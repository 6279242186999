<template>
  <section class="coupons-create-page">
    <b-form @submit.prevent="submitCoupon">
      <b-card header="Informações básicas" class="mb-1">
        <b-card-text>
          <div class="form-row">
            <div class="col-12 col-md-4">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Título interno
              </label>
              <div class="form-group">
                <input
                  v-model="item.title"
                  :class="{ 'is-invalid': $v.item.title.$error }"
                  name="title-internal"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-md-4 col-12">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Código
              </label>
              <div class="form-group">
                <input
                  class="form-control"
                  :class="{ 'is-invalid': $v.item.coupon.$error }"
                  v-model="item.coupon"
                  type="text"
                  name="coupon"
                />
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>
                  <i class="text-danger bi bi-record-circle"></i>
                  Desconto
                </label>
                <b-input-group
                  v-if="item.type_discount.code == 'money'"
                  prepend="R$"
                  class="input-group-merge"
                >
                  <b-form-input />
                </b-input-group>
                <b-input-group
                  v-if="item.type_discount.code == 'percentage'"
                  append="%"
                  class="input-group-merge"
                >
                  <b-form-input
                    v-model="item.value_discount"
                    :class="{
                      'is-invalid': $v.item.value_discount.$error,
                    }"
                    type="number"
                    min="1"
                    max="99"
                    name="discount"
                  />
                </b-input-group>
              </div>
            </div>
            <div class="col-md-2 col-12">
              <div class="form-group">
                <label>
                  <i class="text-danger bi bi-record-circle"></i>
                  Utilização
                  <i
                    class="bi bi-question-circle"
                    v-b-tooltip.hover.top="
                      'Quantidade de vezes que o cupom poderá ser utilizado.'
                    "
                  ></i>
                </label>
                <input
                  v-model="item.limit"
                  :class="{ 'is-invalid': $v.item.limit.$error }"
                  name="quantity"
                  type="number"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-md-3 col-6">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Data de início
                </label>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="CalendarIcon" />
                  </b-input-group-prepend>
                  <flat-pickr
                    v-model="item.start"
                    class="form-control pl-1"
                    :class="{ 'is-invalid': $v.item.start.$error }"
                    :config="{
                      dateFormat: 'd/m/Y',
                      altFormat: 'Y-m-d',
                      locale: 'pt',
                    }"
                  />
                </b-input-group>
              </div>
            </div>
            <div class="col-md-3 col-6">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Data de término
                </label>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="CalendarIcon" />
                  </b-input-group-prepend>
                  <flat-pickr
                    v-model="item.end"
                    :class="{ 'is-invalid': $v.item.end.$error }"
                    class="form-control pl-1"
                    :config="{
                      dateFormat: 'd/m/Y',
                      altFormat: 'Y-m-d',
                      locale: 'pt',
                    }"
                  />
                </b-input-group>
              </div>
            </div>
          </div>
        </b-card-text>
        <b-card-footer class="p-0 pt-1">
          <div class="flex">
            <span>Status do cupom de desconto</span>
            <span>
              <b-form-checkbox
                v-model="item.status"
                checked="true"
                class="custom-control-success float-left"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="PowerIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="PowerIcon" />
                </span>
              </b-form-checkbox>
              <span>
                {{ item.status ? "Ativo" : "Inativo" }}
              </span>
            </span>
          </div>
        </b-card-footer>
      </b-card>
      
      <b-card header="Produto(s)" class="invoice-preview-card">
        <b-card-text>
          <div class="form-row">
            <div class="col-md-12">
              <b-form-radio-group
                class="radio-rules mb-0"
                stacked
                v-model="item.type_to_apply"
                :options="optionsApply"
                value-field="code"
                text-field="title"
              />
            </div>
            <div v-if="item.type_to_apply === 1" class="col-12 pt-2">
              A regra será aplicada para todos os itens no carrinho.
            </div>
            <div v-if="item.type_to_apply === 2" class="col-12 pt-2">
              <b-form-group label="Digite o título do produto">
                <v-select
                  ref="selectProduct"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="searchProduct"
                  placeholder="Digite o título"
                  :options="optionsProducts"
                  @search="fetchProducts"
                  @input="productSelected"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectProduct.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>

              <h5 v-if="item.products.length" class="mt-2">Produtos selecionados</h5>
              <b-list-group flush>
                <b-list-group-item
                  v-for="(product, index) in item.products"
                  :key="index"
                >
                  <div class="form-row">
                    <div class="col-12 text-left">
                      {{ product.title }}
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>

            <div v-if="item.type_to_apply === 3" class="col-12 pt-2">
              <b-form-group label="Digite o título da categoria">
                <v-select
                  ref="selectCategory"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="searchCategory"
                  placeholder="Digite o título"
                  :options="optionsCategories"
                  @search="fetchCategories"
                  @input="categorySelected"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectCategory.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>

              <h5 v-if="item.categories.length" class="mt-2">Categorias selecionados</h5>
              <b-list-group flush>
                <b-list-group-item
                  v-for="(product, index) in item.categories"
                  :key="index"
                >
                  <div class="form-row">
                    <div class="col-12 text-left">
                      {{ product.title }}
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </b-card-text>
      </b-card>

      <div class="row justify-content-right">
        <div class="col-md-3">
          <ButtonsActionsFooter
            routerBack="coupons-list"
            variant="success"
            :submited="submited"
            text="Salvar"
            subtext="Aguarde..."
          />
        </div>
      </div>
    </b-form>
  </section>
</template>

<script>
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import {
  BRow,
  BCol,
  BCard,
  BSidebar,
  BCardText,
  BButton,
  VBToggle,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
  BFormInput,
  BForm,
  BBadge,
  BTableLite,
  BAvatar,
  BMedia,
  BFormTextarea,
  BModal,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BFormSpinbutton,
  BFormRadioGroup,
  VBTooltip,
  BTooltip,
  BCardFooter,
  BCardBody,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Cleave from "vue-cleave-component";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import { VueAutosuggest } from "vue-autosuggest";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

import { required, between, requiredIf } from "vuelidate/lib/validators";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCardFooter,
    BCardBody,
    ButtonsActionsFooter,
    Cleave,
    VueAutosuggest,
    BFormSpinbutton,
    flatPickr,
    BInputGroup,
    BModal,
    BCol,
    BListGroup,
    BListGroupItem,
    BCard,
    BForm,
    BCardBody,
    BTableLite,
    BCardText,
    BBadge,
    BSidebar,
    BButton,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    vSelect,
    BAvatar,
    BMedia,
    BInputGroupPrepend,
    BFormRadioGroup,
    BTooltip,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      uuid: "",
      searchProduct: "",
      searchCategory: "",
      optionsProducts: [],
      optionsCategories: [],
      submited: false,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      item: {
        prefix: "",
        type: 1,
        buy_minimum: 0,
        status: true,
        type_to_apply: 1,
        totalMin: 1,
        enableBuyMin: 0,
        start: "",
        end: "",
        type_discount: {
          code: "percentage",
          title: "%",
        },
        products: [],
        categories: [],
      },
      filteredOptions: [],
      options: {
        date: {
          date: true,
          delimiter: "/",
          datePattern: ["m", "Y"],
        },
      },
      fields: [
        {
          key: "id",
          label: "ID",
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "product",
          label: "Produto",
          class: "text-left",
        },
        {
          key: "discount",
          label: "Desconto",
          class: "text-center",
        },
        {
          key: "paid",
          label: "Preço",
          class: "text-center",
        },
      ],
      optionsApply: [
        { title: "Todos os produtos", code: 1 },
        { title: "Produtos selecionados", code: 2 },
        { title: "Categorias selecionadas", code: 3 },
      ],
      optionsTypeDiscount: [
        // { code: 'money', title: 'R$' },
        { code: "percentage", title: "%" },
      ],
    };
  },
  watch: {
    "item.coupon": {
      handler() {
        this.item.coupon = this.item.coupon.split(" ").join("");
      },
    },
  },
  methods: {
    async getData() {
      this.$store
        .dispatch("Coupon/show", this.$route.params.uuid)
        .then((res) => {
          this.item = res.data;
        });
    },
    productSelected(event) {
      if (event) {
        this.searchProduct = "";
        let checkProduct = false;

        this.item.products.forEach((element) => {
          if (element.code === event.code) {
            checkProduct = true;
          }
        });

        if (!checkProduct) {
          this.item.products.push(event);
        }
      }
    },
    categorySelected(event) {
      if (event) {
        this.searchCategory = "";
        let checkCategory = false;

        this.item.categories.forEach((element) => {
          if (element.code === event.code) {
            checkCategory = true;
          }
        });

        if (!checkCategory) {
          this.item.categories.push(event);
        }
      }
    },
    async fetchProducts(term) {
      this.optionsProducts = [];
      if (term.length > 2) {
        this.$store
          .dispatch("Product/searchOfCategories", {
            term: term,
            categories: [2, 4, 5, 7],
          })
          .then((res) => {
            this.optionsProducts = res.data;
          });
      }
    },

    async fetchCategories(term) {
      this.optionsCategories = [];
      if (term.length > 2) {
        this.$store
          .dispatch("Product/searchCategories", {
            term: term
          })
          .then((res) => {
            console.log(res)
            this.optionsCategories = res.data;
          });
      }
    },

    submitCoupon() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        let dataForm = this.item;
        dataForm.type_discount = dataForm.type_discount
          ? dataForm.type_discount.code
          : "";

        this.$store
          .dispatch("Coupon/update", { uuid: this.uuid, data: dataForm })
          .then(() => {
            this.notifyDefault("success");
            this.$router.push({ name: 'coupons-list' });
          })
          .catch(() => {
            this.submited = false;
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    productType(value) {
      this.item.type = value;
    },
  },
  validations: {
    item: {
      uuid: {
        required,
      },
      title: {
        required,
      },
      coupon: {
        required,
      },
      start: {
        required,
      },
      end: {
        required,
      },
      // buy_minimum: {
      //   required,
      // },
      type_discount: {
        required,
      },
      limit: {
        required,
      },
      value_discount: {
        required,
      },
    },
  },
  created() {
    this.uuid = this.$route.params.uuid;
  },
  mounted() {
    this.getData();
  }
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.coupons-create-page {
  .checkbox-custom-status {
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
}
</style>
